module.exports = {
    fr: {
        label: "Ecrivez-nous",
        project: "Démarrer un projet ensemble",
        support: "Demander du support technique",
        firstname: "Prénom",
        firstnameHelper: "Désolé je n'ai pas compris votre prénom",
        lastname: "Nom",
        lastnameHelper: "Désolé je n'ai pas compris votre nom",
        email: "Adresse email",
        emailHelper: "Nous ne partagerons jamais votre e-mail avec quelqu'un d'autre.",
        emailHelperInvalid: "Votre e-mail n'est pas valide.",
        phone: "Téléphone",
        phoneHelper: "Pourriez-vous fournir votre téléphone ?",
        message: "Message",
        messageHelper: "Veuillez préciser votre demande",
        messagePlaceholder: "Merci de décrire votre besoin et de nous indiquer vos disponibilités pour fixer un rendez-vous.",
        captcha: "Captcha",
        captchaHelper: "C'est pas si difficile!",
        consent: "Acceptez la ",
        send: "Envoyer",
        success: "Merci pour votre message. Il a été envoyé avec succès.",
        error: "Oups! Votre message n'a malheureusement pas pu être envoyé. N'hésitez pas à nous envoyer un mail directement sur contact@swiss-digital-vison.ch"
    },
    en: {
        label: "Write to us",
        project: "Start a new project together",
        support: "Request technical support",
        firstname: "Firstname",
        firstnameHelper: "Sorry didn't get your firstname",
        lastname: "Lastname",
        lastnameHelper: "Sorry didn't get your lastname",
        email: "Email address",
        emailHelper: "We'll never share your email with anyone else.",
        emailHelperInvalid: "Your email is invalid.",
        phone: "Phone",
        phoneHelper: "Could you provide your phone ?",
        message: "Message",
        messageHelper: "Please describe your project",
        messagePlaceholder: "Please describe your needs and indicate your availability so that we can arrange a meeting.",
        captcha: "Captcha",
        captchaHelper: "It's not that difficult!",
        consent: "Accept ",
        send: "Send",
        success: "Thank you for your message. It was sent successfully.",
        error: "Oops! Unfortunately, your message could not be sent. Do not hesitate to send us an email directly on contact@swiss-digital-vision.ch"
    }
};